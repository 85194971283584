/** @format */

import React from "react";
import NavBar from "../components/NavBar";
import Welcome from "../components/Welcome";
import "../assets/styles/Main.scss";
import Timeline from "../components/TimeLine";
import Evenement from "../components/Evenements";
import TopMenu from "../components/TopMenu";

export default function Main() {
  return (
    <div>
      <NavBar />
      <TopMenu AlwaysVisibility={false} />
      <Welcome />
      <Timeline />
      <Evenement />
    </div>
  );
}
