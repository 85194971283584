/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { soundCloudGetLastTracks } from "../utils/API/backend";
import PodcastAtom from "../utils/recoil/atoms/podcastAtom";
import volumeAtom from "../utils/recoil/atoms/volumeAtom";
import vague from "../assets/images/icon/vague.svg"
import {ReactComponent as PlayIcon} from  "../assets/images/icon/Play.svg"

export default function PodcastBigItem({ podcast }) {
  // eslint-disable-next-line
  const [volume, setVolume] = useRecoilState(volumeAtom);
  // eslint-disable-next-line
  const [playingPodcast, setPlayingPodcast] = useRecoilState(PodcastAtom);

  function getLast(id) {
    soundCloudGetLastTracks(id).then((value) => setPlayingPodcast(value[0]));
    document.getElementById("PLA-podcast-main").classList.remove("inactive");
    setVolume(0);
  }

  function setPodcastPlaying(podcast) {
    setPlayingPodcast(podcast)
    document.getElementById("PLA-podcast-main").classList.remove("inactive");
    setVolume(0);
  }

  if(podcast.playlistId){
    return (
      <div className="APO-podcast-bigItem">
        <Link to={`/podcast/${podcast.playlistId}`}><img src={podcast.data.artwork_url} alt="podcast cover" /></Link>
        <img src={vague} alt="vague icon" className="APO-podcast-vague"/>
        <button onClick={() => setPodcastPlaying(podcast)}> <PlayIcon /> Écouter ce podcast</button>
        <h4>{podcast.title}</h4>
        <p>{podcast.data.description}</p>
    </div>
    )
  }else {

  return (
    <div to={`/podcast/${podcast.id}`} className="APO-podcast-bigItem">
        <Link to={`/podcast/${podcast.id}`}><img src={podcast.data.artwork_url} alt="podcast cover" /></Link>
        <img src={vague} alt="vague icon" className="APO-podcast-vague"/>
        <button onClick={() => getLast(podcast.id)}> <PlayIcon /> Écouter la dernière émission</button>
        <h4>{podcast.title}</h4>
        <p>{podcast.data.description}</p>
    </div>
  );}
}
