/** @format */

function searchInList(list, input, tag) {
  let result = []
  //first filter folowing the selected tags
  if(Object.keys(tag).length  !== 0){
    list.forEach(element => {
      if(tag.includes(element.genre.toLowerCase())){
        result.push(element)
      }
    });
  }
  if(Object.keys(tag).length === 0){
    result = [...list]
  }
  //then filter following the search input
  if(input === ""){
    return result
  }else{
    return result.filter((ele) => {
      return ele.title.toLowerCase().indexOf(input) !== -1
    })
  }
}

export { searchInList };
