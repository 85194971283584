/** @format */

import React, { useState, useEffect } from "react";
import "../assets/styles/Player.scss";
import {
  BsPlay,
  BsFillPauseFill,
  BsFillCircleFill,
  BsVolumeUp,
} from "react-icons/bs";
import { useRecoilState } from "recoil";
import playerAtom from "../utils/recoil/atoms/playerAtom";
import volumeAtom from "../utils/recoil/atoms/volumeAtom";
import { fetchCurrentTracks } from "../utils/API/radioKing";
import { ReactComponent as Wave } from "../assets/images/wave.svg";
import PodcastAtom from "../utils/recoil/atoms/podcastAtom";
import backArrow from "../assets/images/icon/backArrow.png";

export default function Player() {
  const [play, setPlay] = useRecoilState(playerAtom);
  const [track, setTrack] = useState([]);
  const [trackTrigger, setTrackTrigger] = useState(false);
  const [time, setTime] = useState(5000);
  const [volume, setVolume] = useRecoilState(volumeAtom);
  const [playingPodcast, setPlayingPodcast] = useRecoilState(PodcastAtom);

  useEffect(() => {
    fetchCurrentTracks().then((query) => {
      setTrack(query);
      const end = new Date(Date.parse(query.next_track));
      const now = new Date();
      let delta = end - now;
      if (delta < 10000) {
        delta = 10000;
      }
      setTime(delta);
    });
  }, [trackTrigger]);

  useEffect(() => {
    setTimeout(() => {
      setTrackTrigger(!trackTrigger);
    }, time);
  }, [time, trackTrigger]);

  useEffect(() => {
    const elem = document.getElementById("volume-slider");
    elem.style.width = volume + "%";
  }, [volume]);

  return (
    <div>
      <div className="PLA-main-grid">
        <Wave className="PLA-background" />
        <div className="PLA-container">
          <button
            className="PLA-playBtn"
            onClick={() => {
              setPlay(!play);
            }}>
            {play ? <BsFillPauseFill /> : <BsPlay />}
          </button>
          <div className="PLA-trackInfo">
            <p>{track.title}</p>
            <BsFillCircleFill />
            <p>{track.artist}</p>
          </div>
          <div className="PLA-volumeController">
            <BsVolumeUp />
            <div className="PLA-volumeSlider">
              <div id="volume-slider"></div>
              <input
                type="range"
                min={0}
                max={100}
                value={volume}
                onChange={(e) => {
                  setVolume(e.target.value);
                }}
              />
              <div id="background-volumeSlider"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="PLA-podcast-main" className="inactive">
        {playingPodcast === null ? null : (
          <div className="PLA-podcast-player">
            <div className="PLA-mask">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <iframe
              id="soundCloudWidget"
              className="PLA-podcast-soundcloud"
              width="75%"
              height="100px"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              title="soundcloudPlayer"
              src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${playingPodcast.id}&color=%23b4d2d8&inverse=false&auto_play=true&show_user=false`}></iframe>
            <button
              onClick={() => {
                document
                  .getElementById("PLA-podcast-main")
                  .classList.add("inactive");
                setVolume(100);
                setPlayingPodcast(null);
              }}>
              <p>Retour au live</p>
              <img src={backArrow} alt="retour au live icone" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
