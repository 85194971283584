/** @format */

import { atom } from "recoil";

const darkAtom = atom({
  key: "darkAtom",
  default: false,
});

export default darkAtom;
