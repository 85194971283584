/** @format */

import React, { useState, useEffect } from "react";
import DayTimeLine from "./DayTimeline";
import { IoIosArrowDown } from "react-icons/io";
import { ReactComponent as Musicalement } from "../assets/images/musicalement.svg";
import { ReactComponent as Naive } from "../assets/images/naive.svg";
import { ReactComponent as Cylindre } from "../assets/images/background/cylindre.svg";
import darkAtom from "../utils/recoil/atoms/darkAtom";

import "../assets/styles/TimeLine.scss";
import { useRecoilValue } from "recoil";

export default function Timeline() {
  const [day, setDay] = useState(null);
  const dark = useRecoilValue(darkAtom);

  useEffect(() => {
    const all = document.querySelectorAll("*");
    dark
      ? all.forEach((element) => {
          element.classList.add("dark");
        })
      : all.forEach((element) => {
          element.classList.remove("dark");
        });
  });

  const handleDay = (element) => {
    setDay(element.target.id);
  };

  useEffect(() => {
    const now = new Date();
    switch (now.getDay() - 1) {
      case -1: //prevent error due now.getDay()
        setDay("dimanche");
        break;
      case 0:
        setDay("lundi");
        break;
      case 1:
        setDay("mardi");
        break;
      case 2:
        setDay("mercredi");
        break;
      case 3:
        setDay("jeudi");
        break;
      case 4:
        setDay("vendredi");
        break;
      case 5:
        setDay("samedi");
        break;
      case 6:
        setDay("dimanche");
        break;
      default:
        setDay("dimanche");
        break;
    }
  }, []);

  useEffect(() => {
    if (day !== null) {
      document
        .getElementById("TIM-week-container")
        .querySelectorAll("button")
        .forEach((element) => {
          element.classList.remove("active");
        });
      document.getElementById(day).classList.add("active");
    }
  }, [day]);

  useEffect(() => {
    const handleScroll = (event) => {
      /*Add a horizontal parallax on naive svg */
      const elemPosition = document
        .getElementById("naive")
        .getBoundingClientRect();
      const vh = window.innerHeight - 100;
      const delta = vh - elemPosition.top;
      //trigger only when in view port
      if (delta > 0 && delta < vh) {
        const coef = delta / vh;
        const min = 478; //svg width
        const max = document.getElementById("week-grid").offsetWidth;
        //adapt to the div width
        let margin = (max - min) * coef;
        //prevent from negative margin
        if (margin < 0) {
          margin = 0;
        }
        document.getElementById("naive").style.marginLeft = margin + "px";
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function nextDay(current) {
    switch (current) {
      case "lundi":
        return "mardi";
      case "mardi":
        return "mercredi";
      case "mercredi":
        return "jeudi";
      case "jeudi":
        return "vendredi";
      case "vendredi":
        return "samedi";
      case "samedi":
        return "dimanche";
      case "dimanche":
        return "lundi"; //la boucle sur la semaine se fait ici
      default:
        break;
    }
  }

  function previousDay(current) {
    switch (current) {
      case "lundi":
        return "dimanche"; //la boucle sur la semaine se fait ici
      case "mardi":
        return "lundi";
      case "mercredi":
        return "mardi";
      case "jeudi":
        return "mercredi";
      case "vendredi":
        return "jeudi";
      case "samedi":
        return "vendredi";
      case "dimanche":
        return "samedi";
      default:
        break;
    }
  }

  return (
    <div className="TIM">
      <Cylindre className="TIM-background-cylindre" />
      <div className="TIM-main" id="programmation">
        <h2>PROGRAMME DE LA SEMAINE</h2>
        <div id="programme-de-la-semaine" className="TIM-content-grid">
          <div className="TIM-week-grid" id="week-grid">
            <div id="TIM-week-container" className="TIM-week-selector">
              <button onClick={handleDay} id="lundi">
                {window.innerWidth > 1000 || day === "lundi" ? "Lundi" : "L"}
              </button>
              <button onClick={handleDay} id="mardi">
                {window.innerWidth > 1000 || day === "mardi" ? "Mardi" : "M"}
              </button>
              <button onClick={handleDay} id="mercredi">
                {window.innerWidth > 1000 || day === "mercredi"
                  ? "Mercredi"
                  : "M"}
              </button>
              <button onClick={handleDay} id="jeudi">
                {window.innerWidth > 1000 || day === "jeudi" ? "Jeudi" : "J"}
              </button>
              <button onClick={handleDay} id="vendredi">
                {window.innerWidth > 1000 || day === "vendredi"
                  ? "Vendredi"
                  : "V"}
              </button>
              <button onClick={handleDay} id="samedi">
                {window.innerWidth > 1000 || day === "samedi" ? "Samedi" : "S"}
              </button>
              <button onClick={handleDay} id="dimanche">
                {window.innerWidth > 1000 || day === "dimanche"
                  ? "Dimanche"
                  : "D"}
              </button>
            </div>
            <div className="TIM-week-arrow-container">
              <button
                onClick={() => {
                  setDay(previousDay(day));
                }}>
                <IoIosArrowDown />
              </button>
              <button
                onClick={() => {
                  setDay(nextDay(day));
                }}>
                <IoIosArrowDown />
              </button>
            </div>
            <div className="TIM-musicalement">
              <Musicalement />
              <Naive id="naive" />
            </div>
          </div>
          <DayTimeLine day={day} />
        </div>
      </div>
    </div>
  );
}
