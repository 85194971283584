/** @format */

import React, { useEffect, useState } from "react";
import "../assets/styles/Main.scss";
import TrackItem from "../components/TrackItem";
import "../assets/styles/Playlist.scss";
import { fetchHistoryTracks } from "../utils/API/radioKing";
import TopMenu from "../components/TopMenu";

export default function Playlist() {
  const [date, setDate] = useState(new Date());
  const [trackList, setTrackList] = useState([]);

  useEffect(() => {
    fetchHistoryTracks(20, date).then((result) => {
      setTrackList(result);
    });
  }, [date]);

  function updateDate() {
    const dateElement = document.getElementById("PLA-dateInput").value;
    const timeElement = document.getElementById("PLA-timeInput").value;
    setDate(
      new Date(
        `${
          dateElement !== ""
            ? dateElement
            : new Date().toISOString().substring(0, 10)
        }T${timeElement !== "" ? timeElement : "00:00"}`
      )
    );
  }

  return (
    <div>
      <TopMenu AlwaysVisibility={true} />
      <div id="PLA-main">
        <div id="PLA-text-container">
          <h2>PLAYLIST</h2>
          <p>
            Musicalement naïve, radio béguin est éclectique et évolutive tout en veillant à mettre en avant des morceaux qualitatifs, peu médiatisés et veille au respect de la parité dans sa programmation. 
Si tu souhaites retrouver ton crush musical, c’est par ici 
          </p>
          <div className="PLA-input-group">
            <div className="PLA-input-container">
              <label>Date</label>
              <input type="date" id="PLA-dateInput" onChange={updateDate} />
            </div>
            <div className="PLA-input-container">
              <label>Heure</label>
              <input type="time" id="PLA-timeInput" onChange={updateDate} />
            </div>
          </div>
        </div>
        <div id="PLA-track-container">
          {trackList.map((track) => {
            return <TrackItem key={track.title} track={track} />;
          })}
        </div>
      </div>
    </div>
  );
}
