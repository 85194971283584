/** @format */

import { radioKingUrl } from "./variables";

/**
 * @param {int} limit of querry
 * @param {Date} date of querry
 */
async function fetchHistoryTracks(limit, date) {
  const query = await fetch(
    radioKingUrl + `track/ckoi?limit=${limit}&date=${date.toISOString()}`,
    {
      headers: {
        Accept: "application/json, text/plain, */*",
      },
      method: "GET",
      mode: "cors",
    }
  );
  const response = await query.json();
  return response;
}

async function fetchCurrentTracks() {
  const query = await fetch(radioKingUrl + "track/current", {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
    method: "GET",
    mode: "cors",
  });
  const response = await query.json();
  return response;
}

export { fetchHistoryTracks, fetchCurrentTracks };
