/** @format */

import React from "react";
import "../assets/styles/Playlist.scss";

export default function trackList({ track }) {
  return (
    <div className="PLA-trackItem-container">
      <p>
        {new Date(track.started_at)
          .toLocaleDateString("fr-FR", {
            hour: "numeric",
            minute: "numeric",
          })
          .substring(11)}
      </p>
      <p>{track.title}</p>
      <div className="PLA-littleCircle"></div>
      <p>{track.artist}</p>
    </div>
  );
}
