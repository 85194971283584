/** @format */

import React, { useEffect, useState } from "react";

import "../assets/styles/TimeLine.scss";
import { fetchTimeLineByDate } from "../utils/API/backend";
import DayTimeItem from "./DayTimeItem";

export default function Timeline({ day }) {
  const [timeLineEvent, setTimeLineEvent] = useState([]);
  const [currentDate, setCurrentDay] = useState(null);

  useEffect(() => {
    var prevMonday = new Date();
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
    switch (day) {
      case "lundi":
        setCurrentDay(prevMonday);
        break;
      case "mardi":
        setCurrentDay(new Date(prevMonday.setDate(prevMonday.getDate() + 1)));
        break;
      case "mercredi":
        setCurrentDay(new Date(prevMonday.setDate(prevMonday.getDate() + 2)));
        break;
      case "jeudi":
        setCurrentDay(new Date(prevMonday.setDate(prevMonday.getDate() + 3)));
        break;
      case "vendredi":
        setCurrentDay(new Date(prevMonday.setDate(prevMonday.getDate() + 4)));
        break;
      case "samedi":
        setCurrentDay(new Date(prevMonday.setDate(prevMonday.getDate() + 5)));
        break;
      case "dimanche":
        setCurrentDay(new Date(prevMonday.setDate(prevMonday.getDate() + 6)));
        break;
      default:
        break;
    }
  }, [day]);

  useEffect(() => {
    if (currentDate !== null) {
      const start = currentDate.toISOString().substring(0, 10);
      const end = new Date(currentDate.setDate(currentDate.getDate() + 1))
        .toISOString()
        .substring(0, 10);
      fetchTimeLineByDate(start, end).then((response) => {
        setTimeLineEvent(response);
      });
    }
  }, [currentDate]);

  return (
    <div className="TIM-program-container">
      <div className="TIM-verticalBar"></div>
      <div className="TIM-item-list">
        {timeLineEvent.map((item) => {
          return <DayTimeItem key={item.id} item={item} />;
        })}
      </div>
    </div>
  );
}
