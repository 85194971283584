/** @format */

import { backend_url } from "./variables";

async function fetchAllPlaylists() {
  const query = await fetch(backend_url + "soundcloud/", {
    method: "GET",
  });
  const response = await query.json();
  return response;
}

async function fetchAllTimeLine() {
  const query = await fetch(backend_url + "timeline/getall/", {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
    method: "GET",
  });
  const response = await query.json();
  return response;
}

async function fetchTimeLineCover(file) {
  const query = await fetch(backend_url + "timeline/getcover/?file=" + file, {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
    method: "GET",
  });
  return query;
}

async function fetchTimeLineByDate(start, end) {
  const query = await fetch(
    backend_url + "timeline/getByDay/?startDate=" + start + "&endDate=" + end,
    {
      headers: {
        Accept: "application/json, text/plain, */*",
      },
      method: "GET",
    }
  );
  const response = await query.json();
  return response;
}

async function fetchAllEvents() {
  const query = await fetch(backend_url + "event/getall/", {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
    method: "GET",
  });
  const response = await query.json();
  return response;
}

async function fetchEventCover(file) {
  const query = await fetch(backend_url + "event/getcover/?file=" + file, {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
    method: "GET",
  });
  return query;
}

async function fetchAllSponsors() {
  const query = await fetch(backend_url + "sponsor/getall/", {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
    method: "GET",
  });
  const response = await query.json();
  return response;
}

async function fetchSponsorsCover(file) {
  const query = await fetch(backend_url + "sponsor/getcover/?file=" + file, {
    headers: {
      Accept: "application/json, text/plain, */*",
    },
    method: "GET",
  });
  return query;
}

async function soundCloudGetAllTracks(playListId) {
  var formdata = new FormData();
  formdata.append("id", playListId);
  const query = await fetch(backend_url + `soundcloudTrack/`, {
    method: "POST",
    body: formdata,
  });
  const response = await query.json();
  return response;
}

async function soundCloudGetLastTracks(playListId) {
  var formdata = new FormData();
  formdata.append("id", playListId);
  const query = await fetch(backend_url + `soundcloudTrackLast`, {
    method: "POST",
    body: formdata,
  });
  const response = await query.json();
  return response;
}

async function soundCloudSearch(field) {
  const query = await fetch(backend_url + `search/?content=`+field, {
    method: "GET",
  });
  const response = await query.json();
  return response;
}

export {
  fetchAllTimeLine,
  fetchTimeLineCover,
  fetchTimeLineByDate,
  fetchAllEvents,
  fetchEventCover,
  fetchAllSponsors,
  fetchSponsorsCover,
  fetchAllPlaylists,
  soundCloudGetAllTracks,
  soundCloudGetLastTracks,
  soundCloudSearch,
};
