/** @format */

import React from "react";
import "../assets/styles/NavBar.scss";
import { ReactComponent as Comet } from "../assets/images/icon/Comet.svg";
import { ReactComponent as Moon } from "../assets/images/icon/Moon.svg";
import { ReactComponent as Sun } from "../assets/images/icon/Sun.svg";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import darkAtom from "../utils/recoil/atoms/darkAtom";

export default function NavBar() {
  const [dark, setDark] = useRecoilState(darkAtom);

  return (
    <div id="NavBar" className="Nav-container">
      <nav>
        <a className="navLink" href="#programme-de-la-semaine">
          Programmation & évènements
        </a>
        <Link className="navLink" to="/podcasts">
          Podcast & émissions
        </Link>
        <Link to="/projet" className="navLink" href="#root">
          Le projet
        </Link>
        <Link className="navLink" to="/playlist">
          Playlist
        </Link>
        <a
          className="donation-btn"
          href="https://www.helloasso.com/associations/radio-beguin"
          target="_blank"
          rel="noreferrer">
          Faire un don
          <Comet />
        </a>
      </nav>
      <button
        onClick={() => {
          setDark(!dark);
        }}
        className="themeSwitch-btn">
        {dark ? <Sun /> : <Moon />}
      </button>
    </div>
  );
}
