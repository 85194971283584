/** @format */

import React, { useEffect, useState } from "react";
import "../assets/styles/Main.scss";
import "../assets/styles/AllPodcasts.scss";
import PodcastBigItem from "../components/PodcastBigItem";
import { fetchAllPlaylists } from "../utils/API/backend";
import playlistAtom from "../utils/recoil/atoms/playlistsAtom";
import { useRecoilState } from "recoil";
import TopMenu from "../components/TopMenu";
import { searchInList } from "../utils/search";
import { soundCloudSearch } from "../utils/API/backend";
import Tag from "../components/Tag";

export default function AllPodcasts() {
  const [podcastList, setPodcastList] = useRecoilState(playlistAtom);
  const [filteredList, setFilteredList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [tagList, setTagList] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);

  useEffect(() => {
    if (searchInput.length !== 0){
      soundCloudSearch(searchInput.replace(" ","%")).then((result) => {
        setFilteredList(result)
      })
    }else{  
      setFilteredList(searchInList(podcastList, searchInput, selectedTag));
    }
    console.log(filteredList)
  }, [searchInput, podcastList, selectedTag]);
  
  useEffect(() => {
    if (podcastList.length === 0) {
      fetchAllPlaylists().then((response) => {
        setPodcastList(
          response.sort((a, b) => {
            return new Date(b.last_modified) - new Date(a.last_modified);
          })
        );
      });
    }
  }, [podcastList.length, setPodcastList]);

  useEffect(() => {
    const tmp = [];
    podcastList.forEach((element) => {
      const tag = element.genre.toLowerCase();
      if (!tmp.includes(tag) && tag !== "") {
        tmp.push(tag.toLowerCase());
      }
    });
    setTagList(tmp);
  }, [podcastList]);

  return (
    <div>
      <TopMenu AlwaysVisibility={true} />
      <div id="APO-main">
        <div id="APO-text">
          <h2>NOS PODCASTS & ÉMISSIONS</h2>
          <input
            type="text"
            placeholder="Chercher une émission ..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <div className="APO-taglist">
            {tagList.map((tag) => {
              return <Tag key={tag} title={tag} setSelectedTag={setSelectedTag} selectedTag={selectedTag}/>
            })}
          </div>
          <p>
            Radio béguin propose de nombreuses émissions ré-écoutables en podcast. 
            DJsets, talk radio, émissions sur divers sujets et retransmissions de live sont à votre disposition. Bonne écoute ! 
            </p>
        </div>
        <div id="APO-podcasts-list">
          {filteredList && filteredList.map((podcast) => {
            return <PodcastBigItem key={podcast.title} podcast={podcast} />;
          })}
        </div>
      </div>
    </div>
  );
}
