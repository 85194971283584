/** @format */

import React, { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { ReactComponent as HalfGlobe } from "../assets/images/background/half-glob.svg";
import { fetchAllEvents } from "../utils/API/backend";

import "../assets/styles/Event.scss";
import EvenementItem from "./EvenementItem";
import { useRecoilValue } from "recoil";
import darkAtom from "../utils/recoil/atoms/darkAtom";

export default function Evenement() {
  const [eventList, setEventList] = useState([]);
  const dark = useRecoilValue(darkAtom);
  const scrollUnit = window.innerWidth * 0.3;

  useEffect(() => {
    const all = document.querySelectorAll("*");
    dark
      ? all.forEach((element) => {
          element.classList.add("dark");
        })
      : all.forEach((element) => {
          element.classList.remove("dark");
        });
  });

  useEffect(() => {
    fetchAllEvents().then((result) => {
      setEventList(result);
    });
  }, []);

  return (
    <div className="EV">
      <HalfGlobe className="EV-background-halfGlob" />
      <div className="EV-main">
        <h2>PROCHAINS ÉVÈNEMENTS</h2>
        <div className="EV-main-container">
          <div className="EV-horizontalBar"></div>
          <div id="EV-list-container" className="EV-list-container">
            {eventList.map((item) => {
              return <EvenementItem key={item.id} item={item} />;
            })}
          </div>
        </div>
        <div className="EV-arrow-container">
          <button
            onClick={() => {
              document.getElementById("EV-list-container").scrollLeft -=
                scrollUnit;
            }}>
            <IoIosArrowDown />
          </button>
          <button
            onClick={() => {
              document.getElementById("EV-list-container").scrollLeft +=
                scrollUnit;
            }}>
            <IoIosArrowDown />
          </button>
        </div>
      </div>
    </div>
  );
}
