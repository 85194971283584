/** @format */

import React, { useState, useEffect } from "react";
import { ReactComponent as Star } from "../assets/images/icon/Star.svg";
import { fetchTimeLineCover } from "../utils/API/backend";
import "../assets/styles/TimeLine.scss";

export default function DayTimeItem({ item }) {
  const hour = item.time.substring(11, 13);
  const minutes = item.time.substring(14, 16);
  const [image, setImage] = useState(null);

  useEffect(() => {
    //fetch cover if exist
    if (item.image !== null) {
      fetchTimeLineCover(item.image)
        .then((response) => response.blob())
        .then((imageBlob) => setImage(URL.createObjectURL(imageBlob)));
    }
  }, [item.image]);

  return (
    <div className="TIM-item">
      <div>
        <Star />
        {item.link ? (
          <p>
            {hour + "h" + minutes + " : "} <a href={item.link}>{item.name}</a>
          </p>
        ) : (
          <p>{hour + "h" + minutes + " : " + item.name}</p>
        )}
      </div>
      {image !== null ? <img src={image} alt="illustration" /> : null}
    </div>
  );
}
