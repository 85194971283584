/** @format */

import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes as Roads,
  Route as Road,
} from "react-router-dom";

//components
import Main from "./pages/Main";
import AllPodcasts from "./pages/AllPodcasts";
import Projet from "./pages/Projet";
import Player from "./components/Player";
import Playlist from "./pages/PlayList";

//recoil
import { useRecoilValue, useRecoilState } from "recoil";
import darkAtom from "./utils/recoil/atoms/darkAtom";

//audio player
import playerAtom from "./utils/recoil/atoms/playerAtom";
import volumeAtom from "./utils/recoil/atoms/volumeAtom";
import { radio_stream_link } from "./utils/API/variables";
import Podcasts from "./pages/Podcast";

export default function Routes() {
  const dark = useRecoilValue(darkAtom);
  const [play, setPlay] = useRecoilState(playerAtom);
  const volume = useRecoilValue(volumeAtom);

  //volume and play control
  useEffect(() => {
    play
      ? document.getElementById("audio-radio").play()
      : document.getElementById("audio-radio").pause();
  }, [play]);

  useEffect(() => {
    document.getElementById("audio-radio").volume = volume / 100;
  }, [volume]);

  //switch theme
  useEffect(() => {
    const all = document.querySelectorAll("*");
    dark
      ? all.forEach((element) => {
          element.classList.add("dark");
        })
      : all.forEach((element) => {
          element.classList.remove("dark");
        });
  });

  useEffect(() => {document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });})

  return (
    <BrowserRouter>
      <audio id="audio-radio" src={radio_stream_link} />
      <Player play={play} setPlay={setPlay} />
      <Roads>
        <Road path="/" element={<Main />} />
        <Road path="/podcasts" element={<AllPodcasts />} />
        <Road path="/projet" element={<Projet />} />
        <Road path="/playlist" element={<Playlist />} />
        <Road path="/podcast/:podcastId" element={<Podcasts />} />
      </Roads>
    </BrowserRouter>
  );
}
