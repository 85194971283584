/** @format */

import React from "react";
import { useRecoilState } from "recoil";
import volumeAtom from "../utils/recoil/atoms/volumeAtom";
import {ReactComponent as PlayIcon} from  "../assets/images/icon/Play.svg"
import vague from "../assets/images/icon/vague.svg"

export default function PodcastLittleItem({ podcast, setPlayingPodcast }) {
  // eslint-disable-next-line
  const [volume, setVolume] = useRecoilState(volumeAtom);

  return (
    <div className="POD-track-container">
      <img src={podcast.data.artwork_url} alt="podcast cover" />
      <div className="POD-track-in-container">
          <h4>{podcast.data.title}</h4>
          <button onClick={() => {
            setPlayingPodcast(podcast);
            document
              .getElementById("PLA-podcast-main")
              .classList.remove("inactive");
            setVolume(0);
            }}>
            <PlayIcon /> Écouter cette émission</button>
          <img src={vague} alt="vague icon" />
          <p>{podcast.data.description}</p>
      </div>
    </div>
  );
}
