/** @format */
const radio_stream_link =
  "https://www.radioking.com/play/radio-beguin-1/559828";
const radioKingUrl = "https://www.radioking.com/widgets/api/v1/radio/284398/";
const backend_url = "https://radiobeguin.com/api/";
const souncloudApiUrl = "https://api-v2.soundcloud.com/";
const soundcloudUser = "815775241";
const soundcloudClientId = "YUKXoArFcqrlQn9tfNHvvyfnDISj04zk";

export {
  radioKingUrl,
  radio_stream_link,
  backend_url,
  souncloudApiUrl,
  soundcloudClientId,
  soundcloudUser,
};
