/** @format */

import { atom } from "recoil";

const volumeAtom = atom({
  key: "volumeAtom",
  default: 100,
});

export default volumeAtom;
