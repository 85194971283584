/** @format */

import React, { useEffect, useState } from "react";
import "../assets/styles/Main.scss";
import { radio_stream_link } from "../utils/API/variables";
import "../assets/styles/Projet.scss";
import { fetchAllSponsors } from "../utils/API/backend";

import { ReactComponent as Monkey } from "../assets/images/monkey.svg";
import { ReactComponent as Comet } from "../assets/images/icon/Comet.svg";

import SponsorsItem from "../components/SponsorsItem";
import TopMenu from "../components/TopMenu";

export default function Projet() {
  const [sponsorsList, setSponsorsList] = useState([]);

  useEffect(() => {
    fetchAllSponsors().then((response) => {
      setSponsorsList(response);
    });
  }, []);

  return (
    <div>
      <audio id="audio-radio" src={radio_stream_link} />
      <TopMenu AlwaysVisibility={true} />
      <div id="PRO-main">
        <h2>LE PROJET</h2>
        <div id="PRO-projet-container">
          <Monkey id="PRO-monkey" />
          <div id="PRO-projet-text-Container">
            <p>
            Radio béguin est une webradio basée à Lyon qui à travers sa jungle musicale donne une place importante à la scène émergente et locale.
7/7 et 24/24, la programmation de radio béguin est réfléchie et évolutive pour vous accompagner au fil de vos journées.
Ponctuée de nombreuses émissions : talk radios, Dj Sets variés et événements en direct, la voix est donnée à celleux que l’on entend que trop rarement. Un espace d’expression qui regorge de nouvelles idées et s’aventure où les rencontres l'amènent à Lyon ou ailleurs.

Personne n’est à l’abri d’avoir le béguin !

Radio béguin is a 7/7 and 24/24 webradio located in Lyon, France.
Through its musical jungle, the radio gives an important place to the emerging and local scene. radio beguin's programming is thoughtful and evolving to accompany you throughout your days.
Punctuated by numerous broadcasts: talk radio, various DJ sets and live events, the voice is given to those who are rarely heard. A space for safe and free expression that is full of new ideas and ventures where encounters take it to Lyon or elsewhere.
“Béguin” is an old world to say “crush” in french !
            </p>
            <a
              className="donation-btn"
              href="https://www.helloasso.com/associations/radio-beguin">
              Faire un don
              <Comet />
            </a>
          </div>
        </div>
        <h2>L'ÉQUIPE</h2>
        <div id="PRO-team-container">
          <p>
          Radio béguin est une association constituée d’une équipe bénévole passionnée et s’entoure de nombreux humains de tous horizons.

Fondateur-ices : Jonas Landais, Camille Viguié, Théophile Demolin
Graphisme et DA : Maria Bohórquez
Développement web : Lucas Sovre
Membres du bureau de l’association : Victor Donnet (Président) et Emile Turbet Delof (Trésorier)
Tous les artistes résident-es et créateur-ices de contenu sonore
Nos ami-es qui nous donnent un coup de main régulièrement et de la force

N’hésitez pas à adhérer à l’association via notre HelloAsso [insérer le lien]

Aucun membre de l’équipe n’est salarié, seules nos motivations et notre temps libre permettent l’existence de radio béguin. Merci de le prendre en considération avec indulgence :)
          </p>
        </div>
        <h2>NOS PARTENAIRES</h2>
        <div id="PRO-sponsors-container">
          <p>
          Radio béguin sait s'entourer, et s’associe souvent à des lieux, collectifs et initiatives locales. Nous remercions nos divers partenaires pour leurs soutiens et leurs contributions :
          </p>
          <div id="PRO-sponsors-list">
            {sponsorsList.length >= 1
              ? sponsorsList.map((sponsors) => {
                  return (
                    <SponsorsItem sponsors={sponsors} key={sponsors.name} />
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
}
