/** @format */

import React, { useEffect, useState } from "react";
import "../assets/styles/Main.scss";
import "../assets/styles/Podcast.scss";
import { useParams } from "react-router-dom";
import {
  fetchAllPlaylists,
  soundCloudGetAllTracks,
} from "../utils/API/backend";
import { useRecoilState } from "recoil";
import playlistAtom from "../utils/recoil/atoms/playlistsAtom";
import podcastAtom from "../utils/recoil/atoms/podcastAtom";
import PodcastLittleItem from "../components/PodcastLittleItem";
import TopMenu from "../components/TopMenu";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../assets/images/icon/Little-arrow.svg";

export default function Podcasts() {
  let { podcastId } = useParams();
  const [PodcastList, setPodcastList] = useState([]);
  const [playlist, setPlaylist] = useRecoilState(playlistAtom);
  const [currentPlaylist, setcurrentPlaylist] = useState([]);
  // eslint-disable-next-line
  const [_, setPlayingPodcast] = useRecoilState(podcastAtom);
  let navigate = useNavigate();

  useEffect(() => {
    if (playlist.length === 0) {
      fetchAllPlaylists().then((response) => {
        setPlaylist(response);
      });
    }
  }, [playlist.length, setPlaylist]);

  useEffect(() => {
    playlist.forEach((element) => {
      if (element.id === podcastId) {
        setcurrentPlaylist(element);
      }
    }, []);
  }, [playlist, podcastId]);

  useEffect(() => {
    soundCloudGetAllTracks(podcastId).then((result) => {
      setPodcastList(result);
    });
  }, [podcastId]);

  return (
    <div>
      <TopMenu AlwaysVisibility={true} />
      <div id="POD-main">
        <div id="POD-text">
          <button onClick={()=>{navigate(-1)}}> <BackArrow style={{"transform" : "scaleX(-1)"}}/> Tous les podcasts</button>
          <img
            src={currentPlaylist.data && currentPlaylist.data.artwork_url}
            alt="podcast main cover"
          />
          <h2>
            {currentPlaylist.title && currentPlaylist.title.toUpperCase()}
          </h2>
          <p>{currentPlaylist.data && currentPlaylist.data.description}</p>
        </div>
        <div id="POD-trackList-container">
          {PodcastList.map((podcast) => {
            return (
              <PodcastLittleItem
                key={podcast.id}
                podcast={podcast}
                setPlayingPodcast={setPlayingPodcast}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
