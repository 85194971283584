/** @format */

import React from "react";
import "../assets/styles/Welcome.scss";
import { ReactComponent as Monkey } from "../assets/images/monkey.svg";
import { ReactComponent as MainTitle } from "../assets/images/main-title.svg";
import { ReactComponent as MainTitleDark } from "../assets/images/main-title-dark.svg";
import { ReactComponent as StampCircle } from "../assets/images/Stamp-circle.svg";
import { ReactComponent as LittleArrow } from "../assets/images/icon/Little-arrow.svg";
import { ReactComponent as PlayIcon } from "../assets/images/icon/Play.svg";
import { ReactComponent as PauseIcon } from "../assets/images/icon/Pause.svg";
import { ReactComponent as Globe } from "../assets/images/background/globe.svg";
import { ReactComponent as Ovale } from "../assets/images/background/Ovals.svg";
import { useRecoilState } from "recoil";
import playerAtom from "../utils/recoil/atoms/playerAtom";
import { IoIosArrowDown } from "react-icons/io";
import { useRecoilValue } from "recoil";
import darkAtom from "../utils/recoil/atoms/darkAtom";
import { useNavigate } from "react-router-dom";

export default function Welcome() {
  const [play, setPlay] = useRecoilState(playerAtom);
  const dark = useRecoilValue(darkAtom);
  const navigate = useNavigate();
  return (
    <div className="WEL-main">
      <Globe className="WEL-backgroud-glob" />
      <Ovale className="WEL-background-oval" />
      <div className="WEL-content-grid">
        <div className="WEL-title">
          {dark ? <MainTitleDark /> : <MainTitle />}

          <div
            className="WEL-bigLector"
            onClick={() => {
              setPlay(!play);
            }}>
            {play ? <PauseIcon /> : <PlayIcon />}
          </div>
        </div>
        <div className="WEL-content-text">
          <Monkey id="WEL-desktop-monkey" />
          <p>
          Radio béguin est une webradio basée à Lyon qui à travers sa jungle musicale donne une place importante à la scène émergente et locale.
          </p>
          <div className="WEL-content-text-sub">
            <Monkey id="WEL-mobile-monkey" />
            <button onClick={()=>{navigate("/projet")}} >
              <p>Découvrir le projet</p> <LittleArrow />
            </button>
          </div>
        </div>
        <div className="WEL-social">
          <div>
            <a target="_blank" href="https://soundcloud.com/radiobeguin">soundcloud</a>
            <a target="_blank" href="https://www.facebook.com/radiobeguin">facebook</a>
            <a target="_blank" href="https://www.instagram.com/radiobeguin/">instagram</a>
          </div>
        </div>
      </div>
      <a className="WEL-arrow-container" href="#programmation">
        <StampCircle id="stamp-circle" />
        <IoIosArrowDown id="arrow-stamp" />
      </a>
    </div>
  );
}
