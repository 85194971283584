/** @format */

import { atom } from "recoil";

const PlayerAtom = atom({
  key: "playerAtom",
  default: false,
});

export default PlayerAtom;
