/** @format */

import React, { useRef } from "react";
import {ReactComponent as Check} from  "../assets/images/icon/check_big.svg"

export default function Tag({ title, setSelectedTag, selectedTag, interactive=true }) {

    const checkRef = useRef();


  if(title !== ""){
  return (
    <button className="APO-tag" onClick={(e) =>{
        if(interactive){
        e.target.classList.toggle("selected");
        checkRef.current.classList.toggle("active");
        }
        if(selectedTag.includes(title)){
          setSelectedTag(selectedTag.filter((ele)=>{return ele !== title}))
        }else{setSelectedTag([...selectedTag,title])}
    }}> {interactive ? <Check ref={checkRef} className="APO-tag-check"/> : null} {title}</button>
  );
  }
  return null
}
