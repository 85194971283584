/** @format */

import React, { useState, useEffect } from "react";
import { ReactComponent as Star } from "../assets/images/icon/Star.svg";
import { ReactComponent as StraightArrow } from "../assets/images/icon/Straight-arrow.svg";
import { fetchEventCover } from "../utils/API/backend";

export default function EvenementItem({ item }) {
  const date = new Date(item.time);
  const [image, setImage] = useState(null);

  useEffect(() => {
    fetchEventCover(item.image)
      .then((response) => response.blob())
      .then((imageBlob) => setImage(URL.createObjectURL(imageBlob)));
  }, [item.image]);

  return (
    <div className="EV-item-container">
      <div className="EV-item-gfx-container">
        <Star />
        <div className="EV-item-verticalBar"></div>
      </div>
      <div className="EV-item-Container">
        <h4>
          {date.toLocaleDateString("fr-FR", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </h4>
        {image !== null ? (
          <img src={image} alt="presentation illustration" />
        ) : null}
        <h3>{item.name}</h3>
        <p>{item.description}</p>
        <a href={item.link}>
          <p>
            {window.innerWidth > 1000
              ? "Voir l'évènement sur Facebook"
              : "Voir sur Facebook"}
          </p>
          <StraightArrow />
        </a>
      </div>
    </div>
  );
}
