/** @format */

import React, { useEffect, useState } from "react";
import { fetchSponsorsCover } from "../utils/API/backend";

export default function SponsorsItem({ sponsors }) {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    fetchSponsorsCover(sponsors.image)
      .then((response) => response.blob())
      .then((imageBlob) => setLogo(URL.createObjectURL(imageBlob)));
  }, [sponsors]);

  return (
    <a className="PRO-sponsors-item" href={sponsors.link}>
      {logo !== null ? <img src={logo} alt={sponsors.name + " logo"} /> : null}
      <h4>{sponsors.name}</h4>
    </a>
  );
}
