/** @format */

import React, { useEffect } from "react";
import { ReactComponent as MainLgogo } from "../assets/images/main-logo.svg";
import { ReactComponent as MainLgogoDark } from "../assets/images/main-logo-dark.svg";
import { ReactComponent as Moon } from "../assets/images/icon/Moon.svg";
import { ReactComponent as Sun } from "../assets/images/icon/Sun.svg";
import { ReactComponent as Stamp } from "../assets/images/Stamp-naive.svg";
import { ReactComponent as Monkey } from "../assets/images/monkey.svg";
import { FaLaptopCode } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { Link } from "react-router-dom";
import darkAtom from "../utils/recoil/atoms/darkAtom";

import cgu from "../assets/cgu.pdf";

import "../assets/styles/TopMenu.scss";

export default function TopMenu({ AlwaysVisibility }) {
  const [dark, setDark] = useRecoilState(darkAtom);

  useEffect(() => {
    const all = document.querySelectorAll("*");
    dark
      ? all.forEach((element) => {
          element.classList.add("dark");
        })
      : all.forEach((element) => {
          element.classList.remove("dark");
        });
  });

  useEffect(() => {
    if (AlwaysVisibility === false) {
      const handleScroll = (event) => {
        const menu = document.getElementById("Menu");
        const elemPosition = document
          .getElementById("NavBar")
          .getBoundingClientRect();
        const vh = window.innerHeight;
        const delta = vh - elemPosition.top - 50;
        //trigger only when in view port
        if (delta > 0 && delta < vh) {
          menu.classList.remove("avaible");
        } else {
          menu.classList.add("avaible");
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  });

  return (
    <div
      id="Menu"
      className={"TM-main " + (AlwaysVisibility === true ? "avaible" : "")}>
      <div className="TM-header-container">
        {AlwaysVisibility === true ? (
          <Link to="/">{!dark ? <MainLgogo /> : <MainLgogoDark />}</Link>
        ) : (
          <a href="#top">{!dark ? <MainLgogo /> : <MainLgogoDark />}</a>
        )}
        <div className="TM-header-rightPart">
          <button
            onClick={() => {
              setDark(!dark);
            }}
            className="themeSwitch-btn">
            {dark ? <Sun /> : <Moon />}
          </button>
          <button
            className="TM-header-close-container"
            onClick={() => {
              document.getElementById("TM-close1").classList.toggle("active");
              document.getElementById("TM-close2").classList.toggle("active");
              document.getElementById("Menu").classList.toggle("active");
            }}>
            <span id="TM-close1"></span>
            <span id="TM-close2"></span>
          </button>
        </div>
      </div>
      <div className="TM-dropDown-container">
        <Link onClick={() => {
              document.getElementById("TM-close1").classList.toggle("active");
              document.getElementById("TM-close2").classList.toggle("active");
              document.getElementById("Menu").classList.toggle("active");
            }} to="/#programme-de-la-semaine">Programme et évènements</Link>
        <Link to="/podcasts">Podcast & émissions</Link>
        <Link to="/projet">Le Projet</Link>
        <Link to="/playlist">Playlist</Link>
        <a
          href="https://www.helloasso.com/associations/radio-beguin"
          target="_blank"
          rel="noreferrer">
          Faire un don
        </a>
        <div className="TM-dropdown-illustration">
          <Stamp id="TM-stamp" />
          <Monkey id="TM-monkey" />
        </div>
        <div className="TM-dropdown-social">
          <a href="https://www.instagram.com/radiobeguin/" rel="noreferrer" target="_blank">INSTAGRAM</a>
          <a href="https://www.facebook.com/radiobeguin" rel="noreferrer" target="_blank">FACEBOOK</a>
          <a href="https://soundcloud.com/radiobeguin" rel="noreferrer" target="_blank">SOUNCLOUD</a>
        </div>
        <a href={cgu} style={{fontSize : "14px"}}>Nos mentions légales</a>
        <a
          className="TM-dev"
          href="https://fr.linkedin.com/in/lucas-sovre-848b60174">
          <p>Ingénieur fullStack : Lucas SOVRE</p>
          <FaLaptopCode />
        </a>
      </div>
    </div>
  );
}
