/** @format */

import { atom } from "recoil";

const PodcastAtom = atom({
  key: "podcastAtom",
  default: false,
});

export default PodcastAtom;
