/** @format */

import { atom } from "recoil";

const playlistAtom = atom({
  key: "playlistAtom",
  default: [],
});

export default playlistAtom;
